@import url(https://fonts.googleapis.com/css?family=Oswald|Poppins&display=swap);
#banner{
    max-height: 700px;
    background: url(/static/media/top-background.325a7a6a.svg);
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
}

#banner h1{
    color: white;
    -webkit-background-clip: text;
            background-clip: text;
    webkit-text-fill-color: transparent;
    font-size: 51px;
    font-weight: bold;
    text-transform: uppercase;
}

#banner header{
    max-width: 1080px;
    padding: 32px 94px 0px 94px;
    margin: auto;
    transition: opacity 0.5s;
}

#banner i.arrow {
    border: solid white;
    border-width: 0 6px 6px 0;
    display: inline-block;
    padding: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    position: absolute;
    /* bottom: 20px; */
    left: 50%;
    margin-left: -10px;
}

#banner .arrow-wrapper{
    width: 100%;
    display: none;
}

#banner header img{
    transition: all 0.5s;
}

#banner.fixed-header header{
    position: fixed;
    width: 100%;
    max-width: 100%;
    margin: auto;
    background: #e28560;
    padding: 14px 0px 14px 0px;
    box-shadow: 0px 0px 75px #525252;
    z-index: 9;
    -webkit-animation: headerTopAnimation 0.2s linear;
            animation: headerTopAnimation 0.2s linear;
}

#banner:not(.fixed-header) header{
    -webkit-animation: headerTopAnimationInverse 0.2s linear !important;
            animation: headerTopAnimationInverse 0.2s linear !important;
}

#banner.fixed-header header > div{
    max-width: 1080px;
    margin: auto;
    padding: 0px 94px 0px 94px;
}

#banner.fixed-header header nav{
    padding-top: 14px;
}

#banner.fixed-header header .logo img{
    max-width: 75px;
}

@-webkit-keyframes pulse {
    0% {
        transform: scaleX(1);
    }
    50% {
        transform: scale3d(1.05,1.05,1.05);
    }
    100% {
        transform: scaleX(1);
    }
}

@keyframes pulse {
    0% {
        transform: scaleX(1);
    }
    50% {
        transform: scale3d(1.05,1.05,1.05);
    }
    100% {
        transform: scaleX(1);
    }
}

@-webkit-keyframes headerTopAnimation {
    0% {
        top: -100px;
    }
    50% {
        top: -50px;
    }
    100% {
        top: 0px;
    }
}

@keyframes headerTopAnimation {
    0% {
        top: -100px;
    }
    50% {
        top: -50px;
    }
    100% {
        top: 0px;
    }
}

@-webkit-keyframes headerTopAnimationInverse {
    0% {
        top: 0px;
    }
    50% {
        top: -50px;
    }
    100% {
        top: -100px;
    }
}

@keyframes headerTopAnimationInverse {
    0% {
        top: 0px;
    }
    50% {
        top: -50px;
    }
    100% {
        top: -100px;
    }
}

#banner .ms-Button{
    margin-bottom: 20px;
}
header{
    padding-top: 32px;
}

header a{
    display: inline-block;
}

header nav{
    float: right;
    padding-top: 37px;
}

header nav a,
header nav span{
    float: left;
    font-family: 'Oswald';
    font-size: 14px;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
    margin-left: 8px;
    text-decoration: none;
}

header nav span{
    font-size: 10px;
    padding-top: 5px;
}

header nav a:hover{
    text-decoration: underline;
}

.banner-contents{
    margin-top: 96px;
    width: 100%;
    display: inline-block;
}

.banner-contents .banner-text{
    color: white;
    max-width: 360px;
    float: left;
}

.banner-contents .banner-text h1{
    margin-bottom: 14px;
}

.banner-contents .banner-text img{
    max-width: 165px;
}

.banner-contents .banner-text p{
    font-size: 16px;
    margin-bottom: 35px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.banner-contents .banner-image{
    float: right;
    max-width: 467px;
}

.banner-contents .banner-image > div{
    margin-right: 0px;
    opacity: 1;
}

.burger-menu{
    width: 46px;
    height: 31px;
    transform: rotate(0deg);
    cursor: pointer;
    position: fixed;
    top: 64px;
    right: 28px;
    display: none;
    z-index: 20;
}

.burger-menu span{
    display: block;
    height: 5px;
    width: 100%;
    background: white;
    transition: all 0.3s ease 0s;
    transform-origin: center center 0px;
    position: absolute;
    border-radius: 5px;
    transform: translate3d(0px, 0px, 0px) rotate(0deg);
    margin-top: -1px;
    box-shadow: 2px 15px 52px 2px rgba(0,0,0,0.75);
}

.burger-menu span.second{
    transition: all 0.075s ease-out 0s;
    opacity: 100;
    top: 15px;
}

.burger-menu span.third{
    transform: translate3d(0px, 30px, 0px) rotate(0deg);
}

.burger-menu.open span{
    transform: translate3d(0px, 15px, 0px) rotate(45deg);
}
.burger-menu.open span.second{
    opacity: 0;
}
.burger-menu.open span.third{
    transform: translate3d(0px, 15px, 0px) rotate(-45deg);
}

.mobile-menu{
    position: fixed;
    top: 100%;
    left: 0px;
    width: 100%;
    height: 100%;

    background-color: #000000;
    z-index: 10;
    transition: all 0.5s;
    opacity: 0;
}

.burger-menu.open + .mobile-menu{
    opacity: 0.9;
    top: 0px;
}

.mobile-menu nav{
    width: 100%;
    padding-top: 100px;
    display: block;
}

.mobile-menu .social-wrapper-mobile{
    text-align: center;
    margin-top: 30px;
}

.social-wrapper-mobile img{
    max-width: 22px;
}

.mobile-menu nav a{
    width: 100%;
    text-align: center;
    display: inline-block;
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 25px;
    font-family: 'Oswald';
}

.mobile-menu .social-wrapper-mobile a{
    margin-right: 20px;
}

.mobile-menu .social-wrapper-mobile a:last-child{
    margin-right: 0px;
}


@media only screen and (max-width: 1024px){
    nav, .banner-contents .banner-image,
    #banner.fixed-header{
        display: none;
    }

    #banner header {
        padding: 32px 28px 0px 28px;
    }

    #banner .arrow-wrapper{
        width: 100%;
        display: block;
        margin-top: 50px;
    }

    .banner-contents .banner-text h1{
        font-size: 52px;
    }

    .banner-contents .banner-text p{
        font-size: 22px;
    }

    .banner-contents .banner-text{
        max-width: 100%;
    }

    #banner .logo img{
        width: 156px;
    }

    #banner{
        background-size: cover;
        background-position: center bottom;
        background-repeat: no-repeat;

        height: 850px;
        object-fit: contain;
        max-height: 850px;
    }

    .burger-menu{
        display: block;
    }

}


@media only screen and (max-width: 600px){
    .banner-contents .banner-text p {
        font-size: 19px;
    }

    .banner-contents .banner-text h1 {
        font-size: 33px;
    }

    #banner button{
        width: 100%;
        margin-bottom:10px;
        padding: 13px !important;
    }

    #banner .arrow-wrapper{
        margin-top: 10px;
    }

}
#comments{
    position: relative;
}

.services-wrapper p{
    text-align: center;
}

.comment-item{
    padding: 20px;
}

.comment-item .bubble-wrapper{
    box-shadow: 0 0 4px 0 rgba(114, 114, 114, 0.5);
    padding: 20px;
    position: relative;
    margin: 1em 15px 2em;
    color: #000;
    border-radius: 10px;
    background: white;
    min-height: 180px;
    transition: all 0.5s;

}

.comment-item .bubble-wrapper:hover{
    box-shadow: 0 0 22px 0 rgba(114, 114, 114, 0.5);
    
}

.comment-item .bubble-wrapper::before {
    background-color: white;
    content: "\A0";
    z-index: -1;
    display: block;
    height: 14px;
    position: absolute;
    bottom: -5px;
    transform: rotate( 120deg ) skew( -47deg );
    -moz-transform: rotate( 120deg ) skew( -47deg );
    -ms-transform: rotate( 120deg ) skew( -47deg );
    -o-transform: rotate( 120deg ) skew( -47deg );
    -webkit-transform: rotate( 120deg ) skew( -47deg );
    width: 30px;
    box-shadow: 0 0 4px 0 rgba(114, 114, 114, 0.5);
}


.comment-item .bubble-wrapper .rating{
    margin-bottom: 20px;
}

.comment-item .bubble-wrapper .rating span{
    width: 15px;
    height: 14px;
    margin-right: 4px;
    float: left;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAqCAYAAAAnH9IiAAAAAXNSR0IArs4c6QAABDFJREFUWAntmFtIVEEYx9tV8RJtRBKlCJVE1pKhuV4yBanoSkRBRC9B+FDUQxeKooegeuuxlwgiCISE3uqlkm5U3gWtQKhtCbPALTKkXVxd7TeLI8ft7JnZs2c3gg4cZuabb/7fb76dM2fOuuY5fE1PT7u7urouT01N7Ufa43K52goKCk6Wl5f/cCqUyykhqdPe3n6d+gnZnim7PB5Pg9frjcTZbTXdtkYlGNTZ2bmezB436a4eGxtrNrHbMjkKzdK4zG3662G/GAgE8mxRxg1yDJp17ANsT5y+sVk0MjJyzGiwW3cMmgfvigoCn/P9/f3zVX6qfkegu7u768nyNlUw+peEQqH4h1Rj2FwXR6Cj0agyyzIsD+rZwcHBBbJtp0wZuqOjo4ksN+kGx3fx6OjoKV1/M7+UoYHQzrIB4PTAwMAiQzupakrQ7BjbiVafVEScmejCcDh8Jtlx0t90T5WdxnJoaCh/eHh4hdvtLmUXKCVwKetzN+Vyo59unbG/GHuX0s/9ISsry8/9saKiYlSlMQdabP7BYHCdAaoUAQlYRJA5/ipxO/1M4DtxxET8jPeLOony5+fnv5Xnl1kIXsHncDjLXWgnWAbGjDOR1uLi4qMxaIAPkd2WDAR2IsSN2IMI8C4n1DKksTMGTdqDGQqYchjBGoPOzs6+itq/AB7loTwdg66qqvrGdtPELL6mnIo0CcAW4T5YXV39Ynb3ELE4+KzkHNHGDrIiTbFtyQIb4t5XU1PzUAjMgRaGvr6+okgk8hjwtaL9ty9gf8Kwu7a29qVkiS0P2RBlZWXlFxwbuXuM9r9UD4plawQWHH9kWsJxevNQv0/GG6UtkyVJ+8wGsZXnbTA+bkJo4Thz3rgH+M74gWluf8jNzd3Cr/7JLM4fy8PoVFJSEma2e5l1q9Ge5voblkRDImAR2zLTEo5Mu3nVP6DcIW3pKMWS4I8dzkXWf+xYZlqCITZFfVy201jmqYBFbC1o4UiW14kynRcxCvmwWKqKoQUtPvvJ9kqVmBP9HN6UydGC5mXjJQta698B8HKVhhY0s1cKqQLp9ussQy1oHSFdKA0/Z5YHgZRCGjC6LmtJUpaVs1amMwyd19vbuyol6J6enmXMPOmPXXabRwS+RTlhBWDWx/HY8pdVZnpyctJSwCRoG6/hek5m2+rq6pqBXs19G79JE19Tk+oZUkIjUGaqHGcE7AnnlE2AbuXr4rXs5uAeYAJHcnJyyvC5gz0q+yzKNRZ9Wm/EsJUAIM/4bmsEbLPP53uVyJcjph+fw/iKj4sWxomjQaIrlKhD2JUvDNZ0wcTExDN8fWKA4XrOMrhEVp8bbNpVdMtYepcYcIBfc/YXZzLfsG1kgu8TiSmhxUACFPJwXOAlswFR8fF7E9GniUSTsXPW8KJ9Al2x1QVIxDUS8S4Zjf++6crAbyQuf98sjJZjAAAAAElFTkSuQmCC);
    background-size: 15px 14px;
}

.comment-item .bubble-wrapper .rating span.active{
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAqCAYAAAAnH9IiAAAAAXNSR0IArs4c6QAABE5JREFUWAntmF1oHFUUx8+5m8YQTBRU0Ejpzm6o5KFFRAr2VdotgopC6seLfRAraEuSldo+lA4+1dLspvZFqtL6JLXggz6YTfG19kEFfZRuN61pqtWXbNB8LHtPz5l0yjg7O/fu7qRZwYXhzpzzP/f85uydO/cOQsK/a/ndu7SGw0DwFCD8pBQc3zI5czHJNJhkZ7MTu3cSwXd89Pn9IsIyH8+mCzOXfFunreq0g2C8JjgVBBafXIs9qOv0PDHoynjuRR4ST0cCsd3zRzpbNyYCTUTIFf0gLr34RRensfUlAl2Z2DPKA2F7fFLavqaLV9l4O4Ym11VcZtcmmehEb6ON03TcwfXq5dcJYCQuie8Tnej963bbjqC5aj11omOtJBe9xLUSE9Z2BH1t4fIbPKcNhzuNvWa9Fxcrine2DU3uaC8BHY3vPtorcRIf7TVbraYgeXjmq5ceX1Uqi3XIEuksAfKcTLvMKZop8CIC/YCoypSCcq/W5aHBnTfQdXWzCN/+L+jfxnPDdcStWussIjIYDLOAIcFhwPv8oPVrcYVf+RXOW+a8V3heLyulyimiXzcXS1f8vB40w+6oAZzm8bnDd3RbyzfzI6bUgfTJ6e9x7shLD9WW/v6F726o20DDPPzv39zU179N1VeWnvwvAMsN8HB5THhVHegvHiPM3f0/4RRelSmUfgbAs92PLIR4Vni9eTo9suVtQLzQ1eDM53EypAeN+8/UnAeeeZW3R591JThzCZ9wCp835QVBr47lJnnoTARtG3uOhcxUKR9kaHiNi4CnlmNB0UadC0cYWFgaKu0D8oL9IGg9xdNKU42vTbqVWQKUGnMK0x9F9R0LxOD7QNOnvMBJRQWvhw2BVzcK32Tgc836j4WWoNn8npe1pi94Zm97VdYseYMdkddk+Fp6cvqrBl/AYIQW7dXx3F6GPh+IW59TxFcyxdKXps4bHsSogBSopSh70jbbPFbQBHpb0oBR/dnmsYSmewRtl8cKmmc9wzeNqLq1Y0Or4hih7+wFt7aD0GoMT61P2OwdjdCVanWEp/qOtvzW8JzHy2cIMEKnwO4vM+SxdtvkM0Lzzvsejee1+9JgfhjN0G1VGudQ4Ql+gOesS+wLCYxFMkLz2sXqiZacvGO+oQDfdTJq2CmU3pdWIR7g1+68z2RuzfliX+N/HnphYHF1uWpKJFC8GjzuZFJn8OC3K2F9xd3XBws397P9MG9OHw37w9cDvX2Dj5z4ejFs969jK13r530kL2J8cbjl9e7vfIzBg0PZzNTM6ShgiXHcc8tOsXSqBwYz/PElz4vdW+G+7l5zPi/vXUPjSWylRT47nstropOh0D/4c9aHPTDw8ebihZbXJfPu8/3LC7V3eOgd4oXYw8G+eTi9ly6WJoO28LkRWgLW1tV6LyFu4sp+00MDn7QDG05+yx29/5/q4ltclOf4g/sqoDrvFKc/D+v+v96oCtwGsxp4mxr4POgAAAAASUVORK5CYII=);
    background-size: 15px 14px;
}

.comment-item p{
    font-size: 15px;
    line-height: 24px;
    font-family: 'Poppins', sans-serif;
    color: #313131;
}

.comment-item .profile-pic{
    float: left;
    width: 54px;
    height: 54px;
    object-fit: contain;
    box-shadow: 0 0 6px 0 rgba(29, 29, 29, 0.5);
    border: solid 4px #ffffff;
    border-radius: 50%;
    margin-right: 10px;
    margin-left: 17px;
}

.comment-item .profile-pic img{
    width: 54px;
    height: 54px;
    object-fit: contain;
    border-radius: 50%;
}

.comment-item span{
    float: left;
    color: #313131;
    border: 2px white;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 14px;
}

.comment-item span.position{
    font-size: 14px;
}

#comments .right-arrow{
    position: absolute;
    right: 95px; top: 0px;
}

.slick-list{
    padding-bottom: 10px;
}

.slick-next, .slick-prev {
    top: 35%;
}

.slick-next img, .slick-prev img {
    transition: box-shadow 0.3s ease-in-out;
    border-radius: 50%;
}

.slick-next img:hover, .slick-prev img:hover {
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}



.slick-arrow {
    z-index: 2;
}

.slick-arrow img{
    max-width: 60px;
    z-index: 2;
}

.slick-prev img{
    transform: rotate(180deg);
}


.slick-next:focus, .slick-next:hover, .slick-prev:focus, .slick-prev:hover {
    border: 0px !important;
}

@media only screen and (max-width: 920px){

    #comments.content-wrapper{
        padding: 0px 28px 0px 28px !important;
    }
}


@media only screen and (max-width: 560px){
    .slick-next, .slick-prev{
        display: none !important;
    }
}


.services-wrapper{
    background: url(/static/media/services@3x.71c16086.png), url(/static/media/tomato1@3x.7ab09aab.png), url(/static/media/leaf1@3x.6f262791.png);
    background-position: right top, left bottom, top left;
    background-repeat: no-repeat;
    background-size: 818px 993px, 137px 156px, 97px 136px;
    padding: 0px 94px 100px 94px;
}

.services-wrapper .comment-item .bubble-wrapper::before{
    display: none;
}
.services-wrapper .comment-item .bubble-wrapper img{
    float: left;
    margin-right: 10px;
    max-width: 40px;
}

.services-wrapper .comment-item .service-title{
    font-family: 'Oswald';
    font-size: 24px;
    text-transform: uppercase;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #313131;
}

.services-wrapper .comment-item .service-text{
    margin-top: 23px;
    font-size: 14px;
    font-weight: 500;
}

.services-wrapper .comment-item .actions-wrapper {
    z-index: 20;
    position: absolute;
    margin-left: -20px;
    bottom: 0px;
    display: flex;
    width: 100%;
}

.services-wrapper .comment-item .actions-wrapper a, 
.services-wrapper .comment-item .actions-wrapper button{
    font-size: 14px;
    font-weight: 500;
    background-color: #d9d9d9;
    padding-top: 10px;
    padding-bottom: 10px;
    text-decoration: none;
    color: #313131;
    margin-right: 2px;
    width: 12.2vw;
    display: block;
    text-align: center;
    float: left;
    width: calc(50% - 2px);
}

.services-wrapper .comment-item .actions-wrapper a:hover{
    background-color: #de7046;
    color: white;
}

.services-wrapper .comment-item .actions-wrapper a:first-child,
.services-wrapper .comment-item .actions-wrapper button:first-child{
    border-bottom-left-radius: 10px;
}

.services-wrapper .comment-item .actions-wrapper a:last-child{
    border-bottom-right-radius: 10px;
}


.services-wrapper .comment-item .bubble-wrapper {
    min-height: 300px;
}

@media only screen and (max-width: 920px){

    #comments.content-wrapper.services-wrapper{
        padding: 0px 28px 100px 28px !important;
    }
}
#store{
    background: url(/static/media/banner-plates.e83ed036.png);
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    padding: 100px 0px 100px 0px;
    text-align: center;
}

#store a, #store .input-animated{
    cursor: default;
}

#store .text-wrapper{
    width: 100%;
    text-align: center;
}

#store .text-wrapper p{
    font-size: 20px;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    margin-bottom: 50px;
}

#store .text-wrapper a{
    color: white;
    cursor: pointer;
}

#store .text-wrapper h2{
    font-size: 45px;
    background: none;
    color: white;
    text-align: center;
}

#store .text-wrapper img{
    margin-right: 20px;
    margin-bottom: 10px;
}

#store .flex-wrapper{
    justify-content: space-between !important;
    width: 100%;
}

@media only screen and (max-width: 1220px){
    #store{
        margin-top: 0px !important;
    }
    #store .flex-wrapper {
        flex-flow: column !important;
    }

    #store .text-wrapper{
        max-width: 100%;
        margin-bottom: 36px;
        text-align: center;
        width: 100%;
    }

    #store .image-wrapper{
        width: 100%;
        text-align: center;
        padding-top: 0px;
    }


    #store .text-wrapper h2{
        text-align: center;
    }

    #store .image-wrapper{
        text-align: center;
        max-width: 100%;
    }
}

@media only screen and (max-width: 735px){
    #store .image-wrapper img{
        width: 100%;
        max-width: 100%;
    }

    #store .image-wrapper{
        text-align: center;
        max-width: 100%;
    }
}
.about-wrapper{
    justify-content: space-between !important;
    width: 100%;
    margin-top: 40px;
}

#web-summit .about-wrapper{
    margin-top: 0px;
}

.about-wrapper img{
    max-width: 500px;
    margin-right: 25px;
}

#web-summit .about-wrapper img{
    max-width: 345px;
    max-height: 335px;
    padding-right: 40px;
    padding-top: 70px;
    margin-bottom: 70px;
}

.about-wrapper > div{
    max-width: 440px;
    font-family: 'Poppins', sans-serif;
    margin-right: -50px;
    opacity: 0.3;
}

.about-wrapper.left > div{
    margin-left: -50px;
    margin-right: 0px;
}

#web-summit .about-wrapper > div{
    margin-right: 0px;
    margin-left: -50px;
    opacity: 0.3;
}

.about-wrapper > div h3{
    font-size: 40px;
    padding-top: 30px;
}

.about-wrapper > div p{
    font-size: 19px;
}

@media only screen and (max-width: 1024px){
    .about-wrapper.flex-wrapper{
        
    }

    .about-wrapper img {
        opacity: 0.2;
        position: relative;
        z-index: -1;
        left: -265px;
    }

    #web-summit .about-wrapper img{
        left: auto;
        text-align: center;
        opacity: 1;
        padding-top: 15px;
        max-width: 100%;
        padding: 15px 0px 0px 0px;
    }

    .about-wrapper > div {
        max-width: 100%;
        width: 100%;
    }

    #web-summit .about-wrapper > img{
        display: block;
    }

    #web-summit .about-wrapper > div{
        margin-right: 0px;
        margin-left: 0px;
        position: relative;
    }
    #web-summit .about-wrapper{
        display: inline;
    }
}

@media only screen and (max-width: 1023px){
    .app-screens{
        background: none !important;
        margin-top: 0px !important;
    }

    .app-screens > div{
        flex-direction: column-reverse!important;
    }

    .about-wrapper img {
        opacity: 1;
        position: relative;
        z-index: -1;
        max-width: 320px;
        left: inherit;
    }
}


.app-screens h3{
    font-size: 45px;
    font-weight: bold;
    text-transform: uppercase;
}

.app-screens p{
    font-size: 24px;
    font-weight: 500;
}

#best-nearby{
    background: url(/static/media/leefs@3x.e031b66e.png);
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 340px 556px;
}

#cook{
    background: url(/static/media/leaf1@3x.6f262791.png);
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 97px 136px;
}

#money{
    background: url(/static/media/tomato1@3x.7ab09aab.png);
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 137px 156px;
}

.bt-action{
    color: #313131;
    border: 1px solid #313131;
    text-decoration: none;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 5px; margin-bottom: 5px;
}
footer{
    background-color: #2d2d2d;
    padding-bottom: 30px;
}

footer .footer-links img{
    max-width: 22px;
    max-height: 22px;
}

footer .logo-wrapper{
    float: left;
    padding-top: 18px;
}

footer .logo-wrapper img{
    max-width: 100px;
}



footer .sitemap-wrapper{
    float: left;
    max-width: 300px;
}

footer .sitemap-wrapper a{
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    font-weight: bolder;
    text-transform: uppercase;
    opacity: 0.49;
    text-decoration: none;
}

footer .sitemap-wrapper a:hover{
    opacity: 1;
    text-decoration: underline;
}

footer .sitemap-wrapper li{
    margin-bottom: 10px;
    display: block;
}

footer .sitemap-wrapper ul{
    margin-left: 60px;
}

footer .form-wrapper{
    float: right;
    margin-top: 20px;
}

footer .form-wrapper label{
    color: white;
    font-size: 28px;
    font-family: 'Oswald';
    font-weight: bold;
    text-transform: uppercase;
}

footer .form-wrapper div.white-wrapper{
    background: white;
    position: relative;
    border-radius: 30px;
    padding: 2px;
    width: 320px;
    height: 48px;
    margin-top: 20px;
}

footer .form-wrapper form{
    margin-bottom: 40px;
}

footer .form-wrapper input[type="email"]{
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    border: 0px;
    outline: none;
    margin-top: 13px;
    margin-left: 20px;
    width: 170px;
}

footer .form-wrapper input[type="submit"]{
    font-size: 14px;
    font-family: 'Oswald', sans-serif;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    background: #de7046;
    border-radius: 30px;
    border: 0px;
    cursor: pointer;
    outline: none;
    text-align: left;
    position: absolute;
    top: 9px;
    right: 8px;
    height: 33px;
    width: 115px;
    padding-left: 13px;
    box-shadow: -9px 37px 122px 0px rgba(0,0,0,0.67);
}

footer .footer-line{
    background-image: linear-gradient(to right, #1f1f1f 70%, rgba(255,255,255,0) 0%);
    background-position: bottom;
    background-size: 20px 2px;
    background-repeat: repeat-x;
    margin-bottom: 16px;
}

footer .form-wrapper a{
    margin-right: 40px;
}

footer .social-wrapper-mobile{
    display: none;
}

footer .form-wrapper span{
    font-size: 12px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    color:white;
    display: inline-block;
}

footer .form-wrapper span.respect{
    width: 100%;
}

footer .form-wrapper span.error-message,
footer .form-wrapper span.success-message{
    color: #de7046;
    font-size: 13px;
    margin-top: 15px;
    margin-left: 15px;
    font-weight: bold;
}

footer .form-wrapper span.success-message{
    color: #558745;
}

footer .form-wrapper .icon-send{
    position: absolute;
    right: 20px;
    top: 19px;
    width: 13px; height: 14px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAOCAYAAAD0f5bSAAAAAXNSR0IArs4c6QAAAO1JREFUKBWNkj8LAXEYxw9hsFm8AYPFZlCSLBZ5CWYpg8lo9ALMNq9A+bNwZDAZpSQvwGJkkM7nue7R3Y8u3/p4/n2/V9exrD/lOE4M2rD/K4KxBgcQraNhKQw5mOGZQsrzbrwaLBjTMIQn3Lz+RRVVAm4WceiCGEVnaMJdBvSA5CfE0IATqLY0Bbjqgmq7AZo8LH0HaceQgaMMPvU1tPYtpe1DAmwZDJU1FOFQhwFUZUkdgSl5r4QbMn84yEMuZoJ5pd5f32nHsQctOKqRuvH1wZYnzuGqW3r5NyygpLuvyrEDk69D2IJAFophnjeCUkcNHOw47wAAAABJRU5ErkJggg==);
    cursor: pointer;
}

footer .copyright{
    font-size: 15px;
    color: #585858;
    width: 100%;
    clear: both;
    text-align: center;
    padding-top: 50px;
}

.loader {
    border: 3px solid #2d2d2d; /* Light grey */
    border-top: 3px solid #d87048; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
    top: 12px;
    right: 12px;
    position: absolute;
}

@-webkit-keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


@media only screen and (max-width: 1210px){
    footer{
        margin-top: 0px;
    }
}


@media only screen and (max-width: 1024px){
    footer{
        margin-top: -50px;
    }

    footer .logo-wrapper{
        display: none;
    }

    footer .sitemap-wrapper a{
        font-size: 16px;
    }

    footer .sitemap-wrapper {
        float: left; 
        max-width: 100%;
        width: 100%;
    }

    footer .sitemap-wrapper {
        float: left; 
        max-width: 100%;
        width: 100%;
        order: 2;
    }

    footer .sitemap-wrapper ul{
        margin-left: 0px;
        padding-left: 0px;
        text-align: center;
    }

    footer .sitemap-wrapper li {
        margin-bottom: 15px;
    }

    footer .form-wrapper {
        float: none;
        width: 100%;
        text-align: center;
        order: 1;
    }

    footer .form-wrapper div.white-wrapper{
        width: 100%;
    }

    footer .form-wrapper input[type="email"] {
        margin-top: 13px;
        width: 100%;
        float: left;
    }

    footer .form-wrapper span{
        float: left;
        width: 50%;
    }

    footer .content-wrapper > div{
        display: inline-flex;
        flex-flow: column;
        width: 100%;
        max-width: 100%;
    }

    footer .social-wrapper-mobile{
        order:3;
        width: 100%;
        margin: auto;
        text-align: center;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex-flow: row;
        justify-content: space-around;
        align-items: flex-start;
        margin-top: 30px;
    }

    footer form+div{
        display: none;
    }

    footer .copyright{
        padding-top: 60px;
    }

}


#banner-simple{
  max-height: 700px;
  background-color: #e28560;
}

#banner-simple header{
  max-width: 1080px;
  padding: 32px 94px 0px 94px;
  margin: auto;
  transition: opacity 0.5s;
}

#banner-simple header img{
  transition: all 0.5s;
}

#banner-simple.fixed-header header{
  position: fixed;
  width: 100%;
  max-width: 100%;
  margin: auto;
  margin-top: -5em;
  background-color: #e28560;
  padding: 14px 0px 14px 0px;
  box-shadow: 0px 0px 75px #525252;
  z-index: 9;
  -webkit-animation: headerTopAnimation 0.2s linear;
          animation: headerTopAnimation 0.2s linear;
}

#banner-simple:not(.fixed-header) header{
  -webkit-animation: headerTopAnimationInverse 0.2s linear !important;
          animation: headerTopAnimationInverse 0.2s linear !important;
}

#banner-simple.fixed-header header > div{
  max-width: 1080px;
  margin: auto;
  padding: 0px 94px 0px 94px;
}

#banner-simple.fixed-header header nav{
  padding-top: 14px;
}

#banner-simple.fixed-header header .logo img{
  max-width: 75px;
}

@-webkit-keyframes headerTopAnimation {
  0% {
      top: -100px;
  }
  50% {
      top: -50px;
  }
  100% {
      top: 0px;
  }
}

@keyframes headerTopAnimation {
  0% {
      top: -100px;
  }
  50% {
      top: -50px;
  }
  100% {
      top: 0px;
  }
}

@-webkit-keyframes headerTopAnimationInverse {
  0% {
      top: 0px;
  }
  50% {
      top: -50px;
  }
  100% {
      top: -100px;
  }
}

@keyframes headerTopAnimationInverse {
  0% {
      top: 0px;
  }
  50% {
      top: -50px;
  }
  100% {
      top: -100px;
  }
}

#banner-simple .ms-Button{
  margin-bottom: 20px;
}

#banner-simple header{
  padding-top: 32px;
  padding-bottom: 46px;
}

#banner-simple header a{
  display: inline-block;
  box-shadow: -2px 17px 60px -20px rgb(233, 80, 24);
}

#banner-simple header nav{
  float: right;
  padding-top: 37px;
}

#banner-simple header nav a,
#banner-simple header nav span{
  float: left;
  font-family: 'Oswald';
  font-size: 21px;
  color: white;
  margin-left: 8px;
  text-decoration: none;
}

#banner-simple header nav span{
  font-size: 10px;
  padding-top: 5px;
}

#banner-simple header nav a:hover{
  text-decoration: underline;
}

.burger-menu{
  width: 46px;
  height: 31px;
  transform: rotate(0deg);
  cursor: pointer;
  position: fixed;
  top: 64px;
  right: 28px;
  display: none;
  z-index: 20;
}

.burger-menu span{
  display: block;
  height: 5px;
  width: 100%;
  background: white;
  transition: all 0.3s ease 0s;
  transform-origin: center center 0px;
  position: absolute;
  border-radius: 5px;
  transform: translate3d(0px, 0px, 0px) rotate(0deg);
  margin-top: -1px;
}

.burger-menu span.second{
  transition: all 0.075s ease-out 0s;
  opacity: 100;
  top: 15px;
}

.burger-menu span.third{
  transform: translate3d(0px, 30px, 0px) rotate(0deg);
}

.burger-menu.open span{
  transform: translate3d(0px, 15px, 0px) rotate(45deg);
}
.burger-menu.open span.second{
  opacity: 0;
}
.burger-menu.open span.third{
  transform: translate3d(0px, 15px, 0px) rotate(-45deg);
}

.mobile-menu{
  position: fixed;
  top: 100%;
  left: 0px;
  width: 100%;
  height: 100%;

  background-color: #000000;
  z-index: 10;
  transition: all 0.5s;
  opacity: 0;
}

.burger-menu.open + .mobile-menu{
  opacity: 0.9;
  top: 0px;
}

.mobile-menu nav{
  width: 100%;
  padding-top: 100px;
  display: block;
}

.mobile-menu .social-wrapper-mobile{
  text-align: center;
  margin-top: 30px;
}

.mobile-menu nav a{
  width: 100%;
  text-align: center;
  display: inline-block;
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 25px;
  font-family: 'Oswald';
}

.mobile-menu .social-wrapper-mobile a{
  margin-right: 20px;
}

.mobile-menu .social-wrapper-mobile a:last-child{
  margin-right: 0px;
}


@media only screen and (max-width: 1024px){
  nav, .banner-contents .banner-image,
  #banner-simple.fixed-header{
      display: none;
  }

  #banner-simple header {
      padding: 32px 28px 0px 28px;
  }

  #banner-simple .arrow-wrapper{
      width: 100%;
      display: block;
      margin-top: 50px;
  }

  .banner-contents .banner-text h1{
      font-size: 52px;
  }

  .banner-contents .banner-text p{
      font-size: 22px;
  }

  .banner-contents .banner-text{
      max-width: 100%;
  }

  #banner-simple .logo img{
      width: 156px;
  }

  #banner-simple{
      height: 150px;
      object-fit: contain;
      background-color: #e28560;
  }

  .burger-menu{
      display: block;
  }

}


@media only screen and (max-width: 600px){
  .banner-contents .banner-text p {
      font-size: 19px;
  }

  .banner-contents .banner-text h1 {
      font-size: 33px;
  }

  #banner-simple button{
      width: 100%;
      margin-bottom:10px;
      padding: 13px !important;
  }

  #banner-simple .arrow-wrapper{
      margin-top: 10px;
  }

}
#accordion {
  margin-left: 15vw;
  margin-right: 15vw;
  margin-bottom: 30vh;
}

#accordion h1 {
  margin: 0;
  font-size: 300%;
  line-height: 2;
}

#accordion p {
  margin-top: 28px;
  margin-bottom: 56px;
  font-size: 130%;
  color: #5d5a6c;
}

#accordion input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

#accordion .row {
  display: flex;
}
#accordion .row .col {
  flex: 1 1;
}
#accordion .row .col:last-child {
  margin-left: 1em;
}

#accordion .tab-content a {
  color: black;
}

/* Accordion styles */
#accordion .tabs {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 2px 24px 0px rgba(0,0,0,0.2);
}

#accordion .tab {
  border:2px solid #e0e7ee;
  width: 100%;
  overflow: hidden;
}
#accordion .tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  font-weight: bold;
  color: #444444;
  cursor: pointer;
  font-size: 130%
}
#accordion .tab-label:hover {
  background-color: rgb(228, 228, 228);
}
#accordion .tab-label::after {
  content: "+";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}
#accordion .tab-content {
  max-height: 0;
  line-height: 1.5;
  background-color: #f5f7f9;
  padding: 0 1em;
  transition: all 0.35s;
  font-size: 110%;
  color: #5d5a6c;
}
#accordion .tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  cursor: pointer;
}


#accordion input:checked + .tab-label {
  color: #de7046;
}
#accordion input:checked + .tab-label::after {
  content: "—";
}
#accordion input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 1em;
}

#accordion #no-faqs {
  display: block;
  text-align: center;
  font-size: 150%;
  color: #5d5a6c;
}

.lds-ring {
  display: block;
  margin: 20px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #de7046;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #de7046 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#beta-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20em;
}

#beta-container #user-types {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

#beta-container #type {
  width: 261px;
  height: 261px;
  border-radius: 8px;
  box-shadow: 0 0 7px 0 rgba(167, 167, 167, 0.5);
  margin: 0.5em;
}

#beta-container #type img {
  display: block;
  padding-top: 2.5em;
  margin-left: auto;
  margin-right: auto;
}

#beta-container #type h1 {
  font-size: 40px;
  font-weight: bold;
  color: #313131;
  text-align: center;
}

#beta-container h1 {
  text-align: center;
  font-size: 45px;
  font-weight: bold;
  color: #313131;
}

#beta-container .grow h1 {
  transition: all 0.2s ease-in-out;
}

#beta-container .grow:hover h1 {
  transform: scale(1.25);
}
#form-banner-simple {
  height: 700px;
  background: url(/static/media/cook_banner.7a41e201.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; /* Center the image */
}

#form-banner-simple header {
  max-width: 1080px;
  padding: 32px 94px 0px 94px;
  margin: auto;
  transition: opacity 0.5s;
  z-index: 999999;
  position: relative;
}

#form-banner-simple header img {
  transition: all 0.5s;
}
/*
#form-banner-simple.fixed-header header {
  position: fixed;
  width: 100%;
  max-width: 100%;
  margin: auto;
  margin-top: -5em;
  background-color: #e28560;
  padding: 14px 0px 14px 0px;
  box-shadow: 0px 0px 75px #525252;
  z-index: 9;
  animation: headerTopAnimation 0.2s linear;
}
*/
#form-banner-simple:not(.fixed-header) header {
  -webkit-animation: headerTopAnimationInverse 0.2s linear !important;
          animation: headerTopAnimationInverse 0.2s linear !important;
}

#form-banner-simple.fixed-header header > div {
  max-width: 1080px;
  margin: auto;
  padding: 0px 94px 0px 94px;
}

#form-banner-simple.fixed-header header nav {
  padding-top: 14px;
}

#form-banner-simple.fixed-header header .logo img {
  max-width: 75px;
}
#title-form-cook {
  text-align: center;
  color: white;
  font-weight: bold;
  text-shadow: 4px 4px 2px rgb(77, 77, 77);
}
#text-form-cook {
  font-weight: bold;
  text-align: center;
  color: white;
}
#text-wrapper {
  margin-top: 130px;
  max-width: 435px;
  text-align: center;
  margin: auto;
  z-index: 9999;
  position: relative;
}

#button-wrapper {
  text-align: center;
  position: relative;
  z-index: 9999;
}

#shadowWrapper {
  width: 100%;
  height: 700px;
  background: #48484869;
  position: absolute;
  top: 0px;
  left: 0px;
}
@-webkit-keyframes headerTopAnimation {
  0% {
    top: -100px;
  }
  50% {
    top: -50px;
  }
  100% {
    top: 0px;
  }
}
@keyframes headerTopAnimation {
  0% {
    top: -100px;
  }
  50% {
    top: -50px;
  }
  100% {
    top: 0px;
  }
}

@-webkit-keyframes headerTopAnimationInverse {
  0% {
    top: 0px;
  }
  50% {
    top: -50px;
  }
  100% {
    top: -100px;
  }
}

@keyframes headerTopAnimationInverse {
  0% {
    top: 0px;
  }
  50% {
    top: -50px;
  }
  100% {
    top: -100px;
  }
}

#form-banner-simple .ms-Button {
  margin-bottom: 20px;
}

#form-banner-simple header {
  padding-top: 32px;
  padding-bottom: 46px;
}

#form-banner-simple header a {
  display: inline-block;
}

#form-banner-simple header nav {
  float: right;
  padding-top: 37px;
}

#form-banner-simple header nav a,
#form-banner-simple header nav span {
  float: left;
  font-family: "Oswald";
  font-size: 21px;
  color: white;
  margin-left: 8px;
  text-decoration: none;
}

#form-banner-simple header nav span {
  font-size: 10px;
  padding-top: 5px;
}

#form-banner-simple header nav a:hover {
  text-decoration: underline;
}

.burger-menu {
  width: 46px;
  height: 31px;
  transform: rotate(0deg);
  cursor: pointer;
  position: fixed;
  top: 64px;
  right: 28px;
  display: none;
  z-index: 20;
}

.burger-menu span {
  display: block;
  height: 5px;
  width: 100%;
  background: white;
  transition: all 0.3s ease 0s;
  transform-origin: center center 0px;
  position: absolute;
  border-radius: 5px;
  transform: translate3d(0px, 0px, 0px) rotate(0deg);
  margin-top: -1px;
}

.burger-menu span.second {
  transition: all 0.075s ease-out 0s;
  opacity: 100;
  top: 15px;
}

.burger-menu span.third {
  transform: translate3d(0px, 30px, 0px) rotate(0deg);
}

.burger-menu.open span {
  transform: translate3d(0px, 15px, 0px) rotate(45deg);
}
.burger-menu.open span.second {
  opacity: 0;
}
.burger-menu.open span.third {
  transform: translate3d(0px, 15px, 0px) rotate(-45deg);
}

.mobile-menu {
  position: fixed;
  top: 100%;
  left: 0px;
  width: 100%;
  height: 100%;

  background-color: #000000;
  z-index: 10;
  transition: all 0.5s;
  opacity: 0;
}

.burger-menu.open + .mobile-menu {
  opacity: 0.9;
  top: 0px;
}

.mobile-menu nav {
  width: 100%;
  padding-top: 100px;
  display: block;
}

.mobile-menu .social-wrapper-mobile {
  text-align: center;
  margin-top: 30px;
}

.mobile-menu nav a {
  width: 100%;
  text-align: center;
  display: inline-block;
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 25px;
  font-family: "Oswald";
}

.mobile-menu .social-wrapper-mobile a {
  margin-right: 20px;
}

.mobile-menu .social-wrapper-mobile a:last-child {
  margin-right: 0px;
}

@media only screen and (max-width: 1024px) {
  nav,
  .banner-contents .banner-image,
  #form-banner-simple.fixed-header {
    display: none;
  }

  #form-banner-simple header {
    padding: 32px 28px 0px 28px;
  }

  #form-banner-simple .arrow-wrapper {
    width: 100%;
    display: block;
    margin-top: 50px;
  }

  .banner-contents .banner-text h1 {
    font-size: 52px;
  }

  .banner-contents .banner-text p {
    font-size: 22px;
  }

  .banner-contents .banner-text {
    max-width: 100%;
  }

  #form-banner-simple .logo img {
    width: 156px;
  }

  #form-banner-simple {
    height: 150px;
    object-fit: contain;
    background-color: #e28560;
  }

  #form-banner-simple.cook-banner{
    height: auto;
  }

  .burger-menu {
    display: block;
  }
}

@media only screen and (max-width: 600px) {
  .banner-contents .banner-text p {
    font-size: 19px;
  }

  .banner-contents .banner-text h1 {
    font-size: 33px;
  }

  #form-banner-simple button {
    width: 100%;
    margin-bottom: 10px;
    padding: 13px !important;
  }

  #form-banner-simple .arrow-wrapper {
    margin-top: 10px;
  }
}

#beta-forms {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20em;
}

#beta-forms h1 {
  text-align: center;
  text-transform: uppercase;
  font-size: 45px;
  font-weight: bold;
  color: #313131;
}

#beta-forms a {
  color: #de7046;
}
.upload-section {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 15px;
  border-color: #eee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  width: 89%;
  margin-left: 1%;
  margin-top: 0 !important;
}
.upload-wrapper {
  margin-top: 0 !important;
}
.thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16;
}

.thumb {
  display: inline-flex;
  border-radius: 2;
  border: 1px solid #eaeaea;
  margin-bottom: 8;
  margin-right: 8;
  width: 100;
  height: 100;
  padding: 4;
  box-sizing: border-box;
}
.cook-experience {
  border-radius: 8px;
  border: solid 1px #c7c7c7;
  font-size: 14px;
  font-weight: 700;
  color: #747474;
  margin: 0.5em;
  padding: 1em;
  background-color: white;
}

.thumbInner {
  display: flex;
  min-width: 0;
  overflow: hidden;
}

.img {
  display: block;
  width: auto;
  height: 100%;
  width: 100px;
}
#beta-forms p {
  opacity: 0.7;
  font-size: 19px;
  line-height: 1.37;
  color: #272727;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
}

#beta-forms #form-wrapper {
  display: flex;
  flex-direction: column;
  width: 500px;
  margin-top: 1em;
}

.cook-form-know a{
  color: #E1805B!important;
  background: white;
  text-align: center;
  border-radius:5px;
  text-decoration: none !important;

  padding: 10px 20px;
  text-align: center;
  border-radius: 28px;
}

#beta-forms input,
#beta-forms textarea {
  border-radius: 8px;
  border: solid 1px #c7c7c7;
  font-size: 14px;
  font-weight: 700;
  color: #747474;
  margin: 0.5em;
  padding: 1em;
}

#beta-forms .shake {
  border: solid 1px #ff3f3f;
  -webkit-animation: shake-horizontal 0.8s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

#beta-forms #country-dropdown,
#beta-forms #region-dropdown {
  border-radius: 8px;
  border: solid 1px #c7c7c7;
  font-size: 14px;
  font-weight: 700;
  background-color: white;
  color: #747474;
  margin: 0.5em;
  padding: 1em;
}

#beta-forms button {
  background-color: #d36a42 !important;
  font-size: 21px !important;
  color: white !important;
  width: 500px;
  margin-top: 1em;
}

#apply-thanks {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

.iconLicense {
  margin-left: 120px;
}

@media only screen and (max-width: 600px) {
  #beta-forms {
    margin-bottom: 10em;
  }

  #beta-forms #form-wrapper {
    width: 40vh;
    margin-top: 1.5em;
  }

  #beta-forms button {
    width: 40vh;
  }

  #beta-forms p {
    width: 40vh;
  }

  #beta-forms h2 {
    width: 40vh;
    font-size: 200%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes shake-horizontal {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    transform: translateX(10px);
  }
  80% {
    transform: translateX(8px);
  }
  90% {
    transform: translateX(-8px);
  }
}
@keyframes shake-horizontal {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    transform: translateX(10px);
  }
  80% {
    transform: translateX(8px);
  }
  90% {
    transform: translateX(-8px);
  }
}


::selection {
  background: #E07F5E; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #E07F5E; /* Gecko Browsers */
}

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6{
  font-family: 'Oswald';
  color: #313131;
}

a{
  font-family: 'Poppins', sans-serif;
  color: white;
  cursor: pointer;
  transition: all 0.5s;
}

.clear{
  clear: both;
}

.input-animated{
  transition: all 0.5s;
  opacity: 0.9;
  cursor: pointer;
}
.input-animated:hover{
  opacity: 1;
}

.flex-wrapper{
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row;
  justify-content: space-around;
  align-items: flex-start;
}

button, button.inverse:hover{
  border-radius: 24px !important;
  background-color: #ffffff !important;
  color: #d87048 !important;
  font-size: 16px !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: bold !important;
  text-align: center !important;
  padding: 4px !important;
  min-width: 152px !important;
  border: 1px solid transparent !important;
  margin-right: 20px !important;
  transition: all 0.5s;
  cursor: pointer;
  outline: none;
}

button.inverse, button:hover{
  background-color: transparent !important;
  border: 1px solid white !important;
  color: black !important;
  transition: all 0.5s;
}

.content-wrapper{
  max-width: 1080px;
  padding: 0px 94px 0px 94px;
  margin: auto;
}

section{
  margin-top: 77px !important
}
section h2{
  text-align: center;
  font-size: 45px;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 13px;
  margin-top: 0px;
  background-position: 50% 66px;
  background-size: 500px 5px;
  background-repeat: no-repeat;
  padding-bottom: 25px;
}

section h2.animated{
  -webkit-animation: borderDownAnim 3s ease-in-out;
          animation: borderDownAnim 3s ease-in-out;
}

.store-button img{
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 11px;
}

.store-button img:hover{
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.cookieConsent{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    text-align: center;
    font-size: 13px;
    padding-bottom: 10px !important;
}

.cookieConsent > div{
  flex: auto !important;
  margin: 15px;
  width: 100%;
}

.cookieConsent button{
  font-size: 12px!important;
  margin: auto !important;
}

@-webkit-keyframes borderDownAnim{
  0%{
      background-position: 40% 66px;  
  }
  to{
      background-position: 50% 66px;
  }
}

@keyframes borderDownAnim{
  0%{
      background-position: 40% 66px;  
  }
  to{
      background-position: 50% 66px;
  }
}

@media only screen and (max-width: 600px){
  .content-wrapper{
      padding: 0px 28px 0px 28px;
  }
}


@media only screen and (min-width: 320px) and (max-width: 479px){}

@media only screen and (min-width: 480px) and (max-width: 767px){}

@media only screen and (min-width: 768px) and (max-width: 991px){}

@media only screen and (min-width: 992px) and (max-width: 1999px){}



