@import url('https://fonts.googleapis.com/css?family=Oswald|Poppins&display=swap');

::selection {
  background: #E07F5E; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #E07F5E; /* Gecko Browsers */
}

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6{
  font-family: 'Oswald';
  color: #313131;
}

a{
  font-family: 'Poppins', sans-serif;
  color: white;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.clear{
  clear: both;
}

.input-animated{
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0.9;
  cursor: pointer;
}
.input-animated:hover{
  opacity: 1;
}

.flex-wrapper{
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row;
  justify-content: space-around;
  align-items: flex-start;
}

button, button.inverse:hover{
  border-radius: 24px !important;
  background-color: #ffffff !important;
  color: #d87048 !important;
  font-size: 16px !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: bold !important;
  text-align: center !important;
  padding: 4px !important;
  min-width: 152px !important;
  border: 1px solid transparent !important;
  margin-right: 20px !important;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
  outline: none;
}

button.inverse, button:hover{
  background-color: transparent !important;
  border: 1px solid white !important;
  color: black !important;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.content-wrapper{
  max-width: 1080px;
  padding: 0px 94px 0px 94px;
  margin: auto;
}

section{
  margin-top: 77px !important
}
section h2{
  text-align: center;
  font-size: 45px;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 13px;
  margin-top: 0px;
  background-position: 50% 66px;
  background-size: 500px 5px;
  background-repeat: no-repeat;
  padding-bottom: 25px;
}

section h2.animated{
  animation: borderDownAnim 3s ease-in-out;
}

.store-button img{
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 11px;
}

.store-button img:hover{
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.cookieConsent{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    text-align: center;
    font-size: 13px;
    padding-bottom: 10px !important;
}

.cookieConsent > div{
  flex: auto !important;
  margin: 15px;
  width: 100%;
}

.cookieConsent button{
  font-size: 12px!important;
  margin: auto !important;
}

@keyframes borderDownAnim{
  0%{
      background-position: 40% 66px;  
  }
  to{
      background-position: 50% 66px;
  }
}

@media only screen and (max-width: 600px){
  .content-wrapper{
      padding: 0px 28px 0px 28px;
  }
}


@media only screen and (min-width: 320px) and (max-width: 479px){}

@media only screen and (min-width: 480px) and (max-width: 767px){}

@media only screen and (min-width: 768px) and (max-width: 991px){}

@media only screen and (min-width: 992px) and (max-width: 1999px){}


