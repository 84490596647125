#accordion {
  margin-left: 15vw;
  margin-right: 15vw;
  margin-bottom: 30vh;
}

#accordion h1 {
  margin: 0;
  font-size: 300%;
  line-height: 2;
}

#accordion p {
  margin-top: 28px;
  margin-bottom: 56px;
  font-size: 130%;
  color: #5d5a6c;
}

#accordion input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

#accordion .row {
  display: flex;
}
#accordion .row .col {
  flex: 1;
}
#accordion .row .col:last-child {
  margin-left: 1em;
}

#accordion .tab-content a {
  color: black;
}

/* Accordion styles */
#accordion .tabs {
  border-radius: 8px;
  overflow: hidden;
  -webkit-box-shadow: 0px 2px 24px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 2px 24px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 2px 24px 0px rgba(0,0,0,0.2);
}

#accordion .tab {
  border:2px solid #e0e7ee;
  width: 100%;
  overflow: hidden;
}
#accordion .tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  font-weight: bold;
  color: #444444;
  cursor: pointer;
  font-size: 130%
}
#accordion .tab-label:hover {
  background-color: rgb(228, 228, 228);
}
#accordion .tab-label::after {
  content: "+";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}
#accordion .tab-content {
  max-height: 0;
  line-height: 1.5;
  background-color: #f5f7f9;
  padding: 0 1em;
  transition: all 0.35s;
  font-size: 110%;
  color: #5d5a6c;
}
#accordion .tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  cursor: pointer;
}


#accordion input:checked + .tab-label {
  color: #de7046;
}
#accordion input:checked + .tab-label::after {
  content: "—";
}
#accordion input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 1em;
}

#accordion #no-faqs {
  display: block;
  text-align: center;
  font-size: 150%;
  color: #5d5a6c;
}

.lds-ring {
  display: block;
  margin: 20px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #de7046;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #de7046 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
