.app-screens h3{
    font-size: 45px;
    font-weight: bold;
    text-transform: uppercase;
}

.app-screens p{
    font-size: 24px;
    font-weight: 500;
}

#best-nearby{
    background: url(../img/new_landing_images/png/leefs@3x.png);
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 340px 556px;
}

#cook{
    background: url(../img/new_landing_images/png/leaf1@3x.png);
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 97px 136px;
}

#money{
    background: url(../img/new_landing_images/png/tomato1@3x.png);
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 137px 156px;
}

.bt-action{
    color: #313131;
    border: 1px solid #313131;
    text-decoration: none;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 5px; margin-bottom: 5px;
}