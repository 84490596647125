.about-wrapper{
    justify-content: space-between !important;
    width: 100%;
    margin-top: 40px;
}

#web-summit .about-wrapper{
    margin-top: 0px;
}

.about-wrapper img{
    max-width: 500px;
    margin-right: 25px;
}

#web-summit .about-wrapper img{
    max-width: 345px;
    max-height: 335px;
    padding-right: 40px;
    padding-top: 70px;
    margin-bottom: 70px;
}

.about-wrapper > div{
    max-width: 440px;
    font-family: 'Poppins', sans-serif;
    margin-right: -50px;
    opacity: 0.3;
}

.about-wrapper.left > div{
    margin-left: -50px;
    margin-right: 0px;
}

#web-summit .about-wrapper > div{
    margin-right: 0px;
    margin-left: -50px;
    opacity: 0.3;
}

.about-wrapper > div h3{
    font-size: 40px;
    padding-top: 30px;
}

.about-wrapper > div p{
    font-size: 19px;
}

@media only screen and (max-width: 1024px){
    .about-wrapper.flex-wrapper{
        
    }

    .about-wrapper img {
        opacity: 0.2;
        position: relative;
        z-index: -1;
        left: -265px;
    }

    #web-summit .about-wrapper img{
        left: auto;
        text-align: center;
        opacity: 1;
        padding-top: 15px;
        max-width: 100%;
        padding: 15px 0px 0px 0px;
    }

    .about-wrapper > div {
        max-width: 100%;
        width: 100%;
    }

    #web-summit .about-wrapper > img{
        display: block;
    }

    #web-summit .about-wrapper > div{
        margin-right: 0px;
        margin-left: 0px;
        position: relative;
    }
    #web-summit .about-wrapper{
        display: inline;
    }
}

@media only screen and (max-width: 1023px){
    .app-screens{
        background: none !important;
        margin-top: 0px !important;
    }

    .app-screens > div{
        flex-direction: column-reverse!important;
    }

    .about-wrapper img {
        opacity: 1;
        position: relative;
        z-index: -1;
        max-width: 320px;
        left: inherit;
    }
}

