#store{
    background: url(../img/new_landing_images/png/banner-plates.png);
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    padding: 100px 0px 100px 0px;
    text-align: center;
}

#store a, #store .input-animated{
    cursor: default;
}

#store .text-wrapper{
    width: 100%;
    text-align: center;
}

#store .text-wrapper p{
    font-size: 20px;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    margin-bottom: 50px;
}

#store .text-wrapper a{
    color: white;
    cursor: pointer;
}

#store .text-wrapper h2{
    font-size: 45px;
    background: none;
    color: white;
    text-align: center;
}

#store .text-wrapper img{
    margin-right: 20px;
    margin-bottom: 10px;
}

#store .flex-wrapper{
    justify-content: space-between !important;
    width: 100%;
}

@media only screen and (max-width: 1220px){
    #store{
        margin-top: 0px !important;
    }
    #store .flex-wrapper {
        flex-flow: column !important;
    }

    #store .text-wrapper{
        max-width: 100%;
        margin-bottom: 36px;
        text-align: center;
        width: 100%;
    }

    #store .image-wrapper{
        width: 100%;
        text-align: center;
        padding-top: 0px;
    }


    #store .text-wrapper h2{
        text-align: center;
    }

    #store .image-wrapper{
        text-align: center;
        max-width: 100%;
    }
}

@media only screen and (max-width: 735px){
    #store .image-wrapper img{
        width: 100%;
        max-width: 100%;
    }

    #store .image-wrapper{
        text-align: center;
        max-width: 100%;
    }
}