footer{
    background-color: #2d2d2d;
    padding-bottom: 30px;
}

footer .footer-links img{
    max-width: 22px;
    max-height: 22px;
}

footer .logo-wrapper{
    float: left;
    padding-top: 18px;
}

footer .logo-wrapper img{
    max-width: 100px;
}



footer .sitemap-wrapper{
    float: left;
    max-width: 300px;
}

footer .sitemap-wrapper a{
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    font-weight: bolder;
    text-transform: uppercase;
    opacity: 0.49;
    text-decoration: none;
}

footer .sitemap-wrapper a:hover{
    opacity: 1;
    text-decoration: underline;
}

footer .sitemap-wrapper li{
    margin-bottom: 10px;
    display: block;
}

footer .sitemap-wrapper ul{
    margin-left: 60px;
}

footer .form-wrapper{
    float: right;
    margin-top: 20px;
}

footer .form-wrapper label{
    color: white;
    font-size: 28px;
    font-family: 'Oswald';
    font-weight: bold;
    text-transform: uppercase;
}

footer .form-wrapper div.white-wrapper{
    background: white;
    position: relative;
    border-radius: 30px;
    padding: 2px;
    width: 320px;
    height: 48px;
    margin-top: 20px;
}

footer .form-wrapper form{
    margin-bottom: 40px;
}

footer .form-wrapper input[type="email"]{
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    border: 0px;
    outline: none;
    margin-top: 13px;
    margin-left: 20px;
    width: 170px;
}

footer .form-wrapper input[type="submit"]{
    font-size: 14px;
    font-family: 'Oswald', sans-serif;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    background: #de7046;
    border-radius: 30px;
    border: 0px;
    cursor: pointer;
    outline: none;
    text-align: left;
    position: absolute;
    top: 9px;
    right: 8px;
    height: 33px;
    width: 115px;
    padding-left: 13px;
    -webkit-box-shadow: -9px 37px 122px 0px rgba(0,0,0,0.67);
    -moz-box-shadow: -9px 37px 122px 0px rgba(0,0,0,0.67);
    box-shadow: -9px 37px 122px 0px rgba(0,0,0,0.67);
}

footer .footer-line{
    background-image: linear-gradient(to right, #1f1f1f 70%, rgba(255,255,255,0) 0%);
    background-position: bottom;
    background-size: 20px 2px;
    background-repeat: repeat-x;
    margin-bottom: 16px;
}

footer .form-wrapper a{
    margin-right: 40px;
}

footer .social-wrapper-mobile{
    display: none;
}

footer .form-wrapper span{
    font-size: 12px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    color:white;
    display: inline-block;
}

footer .form-wrapper span.respect{
    width: 100%;
}

footer .form-wrapper span.error-message,
footer .form-wrapper span.success-message{
    color: #de7046;
    font-size: 13px;
    margin-top: 15px;
    margin-left: 15px;
    font-weight: bold;
}

footer .form-wrapper span.success-message{
    color: #558745;
}

footer .form-wrapper .icon-send{
    position: absolute;
    right: 20px;
    top: 19px;
    width: 13px; height: 14px;
    background: url(../img/send_icon.png);
    cursor: pointer;
}

footer .copyright{
    font-size: 15px;
    color: #585858;
    width: 100%;
    clear: both;
    text-align: center;
    padding-top: 50px;
}

.loader {
    border: 3px solid #2d2d2d; /* Light grey */
    border-top: 3px solid #d87048; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    top: 12px;
    right: 12px;
    position: absolute;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


@media only screen and (max-width: 1210px){
    footer{
        margin-top: 0px;
    }
}


@media only screen and (max-width: 1024px){
    footer{
        margin-top: -50px;
    }

    footer .logo-wrapper{
        display: none;
    }

    footer .sitemap-wrapper a{
        font-size: 16px;
    }

    footer .sitemap-wrapper {
        float: left; 
        max-width: 100%;
        width: 100%;
    }

    footer .sitemap-wrapper {
        float: left; 
        max-width: 100%;
        width: 100%;
        order: 2;
    }

    footer .sitemap-wrapper ul{
        margin-left: 0px;
        padding-left: 0px;
        text-align: center;
    }

    footer .sitemap-wrapper li {
        margin-bottom: 15px;
    }

    footer .form-wrapper {
        float: none;
        width: 100%;
        text-align: center;
        order: 1;
    }

    footer .form-wrapper div.white-wrapper{
        width: 100%;
    }

    footer .form-wrapper input[type="email"] {
        margin-top: 13px;
        width: 100%;
        float: left;
    }

    footer .form-wrapper span{
        float: left;
        width: 50%;
    }

    footer .content-wrapper > div{
        display: inline-flex;
        flex-flow: column;
        width: 100%;
        max-width: 100%;
    }

    footer .social-wrapper-mobile{
        order:3;
        width: 100%;
        margin: auto;
        text-align: center;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex-flow: row;
        justify-content: space-around;
        align-items: flex-start;
        margin-top: 30px;
    }

    footer form+div{
        display: none;
    }

    footer .copyright{
        padding-top: 60px;
    }

}

