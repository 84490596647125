#beta-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20em;
}

#beta-container #user-types {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

#beta-container #type {
  width: 261px;
  height: 261px;
  border-radius: 8px;
  box-shadow: 0 0 7px 0 rgba(167, 167, 167, 0.5);
  margin: 0.5em;
}

#beta-container #type img {
  display: block;
  padding-top: 2.5em;
  margin-left: auto;
  margin-right: auto;
}

#beta-container #type h1 {
  font-size: 40px;
  font-weight: bold;
  color: #313131;
  text-align: center;
}

#beta-container h1 {
  text-align: center;
  font-size: 45px;
  font-weight: bold;
  color: #313131;
}

#beta-container .grow h1 {
  transition: all 0.2s ease-in-out;
}

#beta-container .grow:hover h1 {
  transform: scale(1.25);
}