#beta-forms {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20em;
}

#beta-forms h1 {
  text-align: center;
  text-transform: uppercase;
  font-size: 45px;
  font-weight: bold;
  color: #313131;
}

#beta-forms a {
  color: #de7046;
}
.upload-section {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 15px;
  border-color: #eee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  width: 89%;
  margin-left: 1%;
  margin-top: 0 !important;
}
.upload-wrapper {
  margin-top: 0 !important;
}
.thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16;
}

.thumb {
  display: inline-flex;
  border-radius: 2;
  border: 1px solid #eaeaea;
  margin-bottom: 8;
  margin-right: 8;
  width: 100;
  height: 100;
  padding: 4;
  box-sizing: border-box;
}
.cook-experience {
  border-radius: 8px;
  border: solid 1px #c7c7c7;
  font-size: 14px;
  font-weight: 700;
  color: #747474;
  margin: 0.5em;
  padding: 1em;
  background-color: white;
}

.thumbInner {
  display: flex;
  min-width: 0;
  overflow: hidden;
}

.img {
  display: block;
  width: auto;
  height: 100%;
  width: 100px;
}
#beta-forms p {
  opacity: 0.7;
  font-size: 19px;
  line-height: 1.37;
  color: #272727;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
}

#beta-forms #form-wrapper {
  display: flex;
  flex-direction: column;
  width: 500px;
  margin-top: 1em;
}

.cook-form-know a{
  color: #E1805B!important;
  background: white;
  text-align: center;
  border-radius:5px;
  text-decoration: none !important;

  padding: 10px 20px;
  text-align: center;
  border-radius: 28px;
}

#beta-forms input,
#beta-forms textarea {
  border-radius: 8px;
  border: solid 1px #c7c7c7;
  font-size: 14px;
  font-weight: 700;
  color: #747474;
  margin: 0.5em;
  padding: 1em;
}

#beta-forms .shake {
  border: solid 1px #ff3f3f;
  -webkit-animation: shake-horizontal 0.8s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

#beta-forms #country-dropdown,
#beta-forms #region-dropdown {
  border-radius: 8px;
  border: solid 1px #c7c7c7;
  font-size: 14px;
  font-weight: 700;
  background-color: white;
  color: #747474;
  margin: 0.5em;
  padding: 1em;
}

#beta-forms button {
  background-color: #d36a42 !important;
  font-size: 21px !important;
  color: white !important;
  width: 500px;
  margin-top: 1em;
}

#apply-thanks {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

.iconLicense {
  margin-left: 120px;
}

@media only screen and (max-width: 600px) {
  #beta-forms {
    margin-bottom: 10em;
  }

  #beta-forms #form-wrapper {
    width: 40vh;
    margin-top: 1.5em;
  }

  #beta-forms button {
    width: 40vh;
  }

  #beta-forms p {
    width: 40vh;
  }

  #beta-forms h2 {
    width: 40vh;
    font-size: 200%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}
@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}
