.services-wrapper{
    background: url(../img/new_landing_images/png/services@3x.png), url(../img/new_landing_images/png/tomato1@3x.png), url(../img/new_landing_images/png/leaf1@3x.png);
    background-position: right top, left bottom, top left;
    background-repeat: no-repeat;
    background-size: 818px 993px, 137px 156px, 97px 136px;
    padding: 0px 94px 100px 94px;
}

.services-wrapper .comment-item .bubble-wrapper::before{
    display: none;
}
.services-wrapper .comment-item .bubble-wrapper img{
    float: left;
    margin-right: 10px;
    max-width: 40px;
}

.services-wrapper .comment-item .service-title{
    font-family: 'Oswald';
    font-size: 24px;
    text-transform: uppercase;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #313131;
}

.services-wrapper .comment-item .service-text{
    margin-top: 23px;
    font-size: 14px;
    font-weight: 500;
}

.services-wrapper .comment-item .actions-wrapper {
    z-index: 20;
    position: absolute;
    margin-left: -20px;
    bottom: 0px;
    display: flex;
    width: 100%;
}

.services-wrapper .comment-item .actions-wrapper a, 
.services-wrapper .comment-item .actions-wrapper button{
    font-size: 14px;
    font-weight: 500;
    background-color: #d9d9d9;
    padding-top: 10px;
    padding-bottom: 10px;
    text-decoration: none;
    color: #313131;
    margin-right: 2px;
    width: 12.2vw;
    display: block;
    text-align: center;
    float: left;
    width: calc(50% - 2px);
}

.services-wrapper .comment-item .actions-wrapper a:hover{
    background-color: #de7046;
    color: white;
}

.services-wrapper .comment-item .actions-wrapper a:first-child,
.services-wrapper .comment-item .actions-wrapper button:first-child{
    border-bottom-left-radius: 10px;
}

.services-wrapper .comment-item .actions-wrapper a:last-child{
    border-bottom-right-radius: 10px;
}


.services-wrapper .comment-item .bubble-wrapper {
    min-height: 300px;
}

@media only screen and (max-width: 920px){

    #comments.content-wrapper.services-wrapper{
        padding: 0px 28px 100px 28px !important;
    }
}