#banner-simple{
  max-height: 700px;
  background-color: #e28560;
}

#banner-simple header{
  max-width: 1080px;
  padding: 32px 94px 0px 94px;
  margin: auto;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

#banner-simple header img{
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

#banner-simple.fixed-header header{
  position: fixed;
  width: 100%;
  max-width: 100%;
  margin: auto;
  margin-top: -5em;
  background-color: #e28560;
  padding: 14px 0px 14px 0px;
  box-shadow: 0px 0px 75px #525252;
  z-index: 9;
  animation: headerTopAnimation 0.2s linear;
}

#banner-simple:not(.fixed-header) header{
  animation: headerTopAnimationInverse 0.2s linear !important;
}

#banner-simple.fixed-header header > div{
  max-width: 1080px;
  margin: auto;
  padding: 0px 94px 0px 94px;
}

#banner-simple.fixed-header header nav{
  padding-top: 14px;
}

#banner-simple.fixed-header header .logo img{
  max-width: 75px;
}

@keyframes headerTopAnimation {
  0% {
      top: -100px;
  }
  50% {
      top: -50px;
  }
  100% {
      top: 0px;
  }
}

@keyframes headerTopAnimationInverse {
  0% {
      top: 0px;
  }
  50% {
      top: -50px;
  }
  100% {
      top: -100px;
  }
}

#banner-simple .ms-Button{
  margin-bottom: 20px;
}

#banner-simple header{
  padding-top: 32px;
  padding-bottom: 46px;
}

#banner-simple header a{
  display: inline-block;
  -webkit-box-shadow: -2px 17px 60px -20px rgb(233, 80, 24);
  -moz-box-shadow: -2px 17px 60px -20px rgb(233, 80, 24);
  box-shadow: -2px 17px 60px -20px rgb(233, 80, 24);
}

#banner-simple header nav{
  float: right;
  padding-top: 37px;
}

#banner-simple header nav a,
#banner-simple header nav span{
  float: left;
  font-family: 'Oswald';
  font-size: 21px;
  color: white;
  margin-left: 8px;
  text-decoration: none;
}

#banner-simple header nav span{
  font-size: 10px;
  padding-top: 5px;
}

#banner-simple header nav a:hover{
  text-decoration: underline;
}

.burger-menu{
  width: 46px;
  height: 31px;
  transform: rotate(0deg);
  cursor: pointer;
  position: fixed;
  top: 64px;
  right: 28px;
  display: none;
  z-index: 20;
}

.burger-menu span{
  display: block;
  height: 5px;
  width: 100%;
  background: white;
  transition: all 0.3s ease 0s;
  transform-origin: center center 0px;
  position: absolute;
  border-radius: 5px;
  transform: translate3d(0px, 0px, 0px) rotate(0deg);
  margin-top: -1px;
}

.burger-menu span.second{
  transition: all 0.075s ease-out 0s;
  opacity: 100;
  top: 15px;
}

.burger-menu span.third{
  transform: translate3d(0px, 30px, 0px) rotate(0deg);
}

.burger-menu.open span{
  transform: translate3d(0px, 15px, 0px) rotate(45deg);
}
.burger-menu.open span.second{
  opacity: 0;
}
.burger-menu.open span.third{
  transform: translate3d(0px, 15px, 0px) rotate(-45deg);
}

.mobile-menu{
  position: fixed;
  top: 100%;
  left: 0px;
  width: 100%;
  height: 100%;

  background-color: #000000;
  z-index: 10;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0;
}

.burger-menu.open + .mobile-menu{
  opacity: 0.9;
  top: 0px;
}

.mobile-menu nav{
  width: 100%;
  padding-top: 100px;
  display: block;
}

.mobile-menu .social-wrapper-mobile{
  text-align: center;
  margin-top: 30px;
}

.mobile-menu nav a{
  width: 100%;
  text-align: center;
  display: inline-block;
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 25px;
  font-family: 'Oswald';
}

.mobile-menu .social-wrapper-mobile a{
  margin-right: 20px;
}

.mobile-menu .social-wrapper-mobile a:last-child{
  margin-right: 0px;
}


@media only screen and (max-width: 1024px){
  nav, .banner-contents .banner-image,
  #banner-simple.fixed-header{
      display: none;
  }

  #banner-simple header {
      padding: 32px 28px 0px 28px;
  }

  #banner-simple .arrow-wrapper{
      width: 100%;
      display: block;
      margin-top: 50px;
  }

  .banner-contents .banner-text h1{
      font-size: 52px;
  }

  .banner-contents .banner-text p{
      font-size: 22px;
  }

  .banner-contents .banner-text{
      max-width: 100%;
  }

  #banner-simple .logo img{
      width: 156px;
  }

  #banner-simple{
      height: 150px;
      object-fit: contain;
      background-color: #e28560;
  }

  .burger-menu{
      display: block;
  }

}


@media only screen and (max-width: 600px){
  .banner-contents .banner-text p {
      font-size: 19px;
  }

  .banner-contents .banner-text h1 {
      font-size: 33px;
  }

  #banner-simple button{
      width: 100%;
      margin-bottom:10px;
      padding: 13px !important;
  }

  #banner-simple .arrow-wrapper{
      margin-top: 10px;
  }

}