#comments{
    position: relative;
}

.services-wrapper p{
    text-align: center;
}

.comment-item{
    padding: 20px;
}

.comment-item .bubble-wrapper{
    box-shadow: 0 0 4px 0 rgba(114, 114, 114, 0.5);
    padding: 20px;
    position: relative;
    margin: 1em 15px 2em;
    color: #000;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background: white;
    min-height: 180px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;

}

.comment-item .bubble-wrapper:hover{
    box-shadow: 0 0 22px 0 rgba(114, 114, 114, 0.5);
    
}

.comment-item .bubble-wrapper::before {
    background-color: white;
    content: "\A0";
    z-index: -1;
    display: block;
    height: 14px;
    position: absolute;
    bottom: -5px;
    transform: rotate( 120deg ) skew( -47deg );
    -moz-transform: rotate( 120deg ) skew( -47deg );
    -ms-transform: rotate( 120deg ) skew( -47deg );
    -o-transform: rotate( 120deg ) skew( -47deg );
    -webkit-transform: rotate( 120deg ) skew( -47deg );
    width: 30px;
    box-shadow: 0 0 4px 0 rgba(114, 114, 114, 0.5);
}


.comment-item .bubble-wrapper .rating{
    margin-bottom: 20px;
}

.comment-item .bubble-wrapper .rating span{
    width: 15px;
    height: 14px;
    margin-right: 4px;
    float: left;
    background: url("../img/star_inactive@3x.png");
    background-size: 15px 14px;
}

.comment-item .bubble-wrapper .rating span.active{
    background: url("../img/star_active@3x.png");
    background-size: 15px 14px;
}

.comment-item p{
    font-size: 15px;
    line-height: 24px;
    font-family: 'Poppins', sans-serif;
    color: #313131;
}

.comment-item .profile-pic{
    float: left;
    width: 54px;
    height: 54px;
    object-fit: contain;
    box-shadow: 0 0 6px 0 rgba(29, 29, 29, 0.5);
    border: solid 4px #ffffff;
    border-radius: 50%;
    margin-right: 10px;
    margin-left: 17px;
}

.comment-item .profile-pic img{
    width: 54px;
    height: 54px;
    object-fit: contain;
    border-radius: 50%;
}

.comment-item span{
    float: left;
    color: #313131;
    border: 2px white;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 14px;
}

.comment-item span.position{
    font-size: 14px;
}

#comments .right-arrow{
    position: absolute;
    right: 95px; top: 0px;
}

.slick-list{
    padding-bottom: 10px;
}

.slick-next, .slick-prev {
    top: 35%;
}

.slick-next img, .slick-prev img {
    transition: box-shadow 0.3s ease-in-out;
    border-radius: 50%;
}

.slick-next img:hover, .slick-prev img:hover {
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}



.slick-arrow {
    z-index: 2;
}

.slick-arrow img{
    max-width: 60px;
    z-index: 2;
}

.slick-prev img{
    transform: rotate(180deg);
}


.slick-next:focus, .slick-next:hover, .slick-prev:focus, .slick-prev:hover {
    border: 0px !important;
}

@media only screen and (max-width: 920px){

    #comments.content-wrapper{
        padding: 0px 28px 0px 28px !important;
    }
}


@media only screen and (max-width: 560px){
    .slick-next, .slick-prev{
        display: none !important;
    }
}

