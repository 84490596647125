#banner{
    max-height: 700px;
    background: url(../img/new_landing_images/svg/top-background.svg);
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
}

#banner h1{
    color: white;
    background-clip: text;
    webkit-text-fill-color: transparent;
    font-size: 51px;
    font-weight: bold;
    text-transform: uppercase;
}

#banner header{
    max-width: 1080px;
    padding: 32px 94px 0px 94px;
    margin: auto;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
}

#banner i.arrow {
    border: solid white;
    border-width: 0 6px 6px 0;
    display: inline-block;
    padding: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    position: absolute;
    /* bottom: 20px; */
    left: 50%;
    margin-left: -10px;
}

#banner .arrow-wrapper{
    width: 100%;
    display: none;
}

#banner header img{
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

#banner.fixed-header header{
    position: fixed;
    width: 100%;
    max-width: 100%;
    margin: auto;
    background: #e28560;
    padding: 14px 0px 14px 0px;
    box-shadow: 0px 0px 75px #525252;
    z-index: 9;
    animation: headerTopAnimation 0.2s linear;
}

#banner:not(.fixed-header) header{
    animation: headerTopAnimationInverse 0.2s linear !important;
}

#banner.fixed-header header > div{
    max-width: 1080px;
    margin: auto;
    padding: 0px 94px 0px 94px;
}

#banner.fixed-header header nav{
    padding-top: 14px;
}

#banner.fixed-header header .logo img{
    max-width: 75px;
}

@keyframes pulse {
    0% {
        transform: scaleX(1);
    }
    50% {
        transform: scale3d(1.05,1.05,1.05);
    }
    100% {
        transform: scaleX(1);
    }
}

@keyframes headerTopAnimation {
    0% {
        top: -100px;
    }
    50% {
        top: -50px;
    }
    100% {
        top: 0px;
    }
}

@keyframes headerTopAnimationInverse {
    0% {
        top: 0px;
    }
    50% {
        top: -50px;
    }
    100% {
        top: -100px;
    }
}

#banner .ms-Button{
    margin-bottom: 20px;
}
header{
    padding-top: 32px;
}

header a{
    display: inline-block;
}

header nav{
    float: right;
    padding-top: 37px;
}

header nav a,
header nav span{
    float: left;
    font-family: 'Oswald';
    font-size: 14px;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
    margin-left: 8px;
    text-decoration: none;
}

header nav span{
    font-size: 10px;
    padding-top: 5px;
}

header nav a:hover{
    text-decoration: underline;
}

.banner-contents{
    margin-top: 96px;
    width: 100%;
    display: inline-block;
}

.banner-contents .banner-text{
    color: white;
    max-width: 360px;
    float: left;
}

.banner-contents .banner-text h1{
    margin-bottom: 14px;
}

.banner-contents .banner-text img{
    max-width: 165px;
}

.banner-contents .banner-text p{
    font-size: 16px;
    margin-bottom: 35px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.banner-contents .banner-image{
    float: right;
    max-width: 467px;
}

.banner-contents .banner-image > div{
    margin-right: 0px;
    opacity: 1;
}

.burger-menu{
    width: 46px;
    height: 31px;
    transform: rotate(0deg);
    cursor: pointer;
    position: fixed;
    top: 64px;
    right: 28px;
    display: none;
    z-index: 20;
}

.burger-menu span{
    display: block;
    height: 5px;
    width: 100%;
    background: white;
    transition: all 0.3s ease 0s;
    transform-origin: center center 0px;
    position: absolute;
    border-radius: 5px;
    transform: translate3d(0px, 0px, 0px) rotate(0deg);
    margin-top: -1px;
    box-shadow: 2px 15px 52px 2px rgba(0,0,0,0.75);
}

.burger-menu span.second{
    transition: all 0.075s ease-out 0s;
    opacity: 100;
    top: 15px;
}

.burger-menu span.third{
    transform: translate3d(0px, 30px, 0px) rotate(0deg);
}

.burger-menu.open span{
    transform: translate3d(0px, 15px, 0px) rotate(45deg);
}
.burger-menu.open span.second{
    opacity: 0;
}
.burger-menu.open span.third{
    transform: translate3d(0px, 15px, 0px) rotate(-45deg);
}

.mobile-menu{
    position: fixed;
    top: 100%;
    left: 0px;
    width: 100%;
    height: 100%;

    background-color: #000000;
    z-index: 10;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    opacity: 0;
}

.burger-menu.open + .mobile-menu{
    opacity: 0.9;
    top: 0px;
}

.mobile-menu nav{
    width: 100%;
    padding-top: 100px;
    display: block;
}

.mobile-menu .social-wrapper-mobile{
    text-align: center;
    margin-top: 30px;
}

.social-wrapper-mobile img{
    max-width: 22px;
}

.mobile-menu nav a{
    width: 100%;
    text-align: center;
    display: inline-block;
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 25px;
    font-family: 'Oswald';
}

.mobile-menu .social-wrapper-mobile a{
    margin-right: 20px;
}

.mobile-menu .social-wrapper-mobile a:last-child{
    margin-right: 0px;
}


@media only screen and (max-width: 1024px){
    nav, .banner-contents .banner-image,
    #banner.fixed-header{
        display: none;
    }

    #banner header {
        padding: 32px 28px 0px 28px;
    }

    #banner .arrow-wrapper{
        width: 100%;
        display: block;
        margin-top: 50px;
    }

    .banner-contents .banner-text h1{
        font-size: 52px;
    }

    .banner-contents .banner-text p{
        font-size: 22px;
    }

    .banner-contents .banner-text{
        max-width: 100%;
    }

    #banner .logo img{
        width: 156px;
    }

    #banner{
        background-size: cover;
        background-position: center bottom;
        background-repeat: no-repeat;

        height: 850px;
        object-fit: contain;
        max-height: 850px;
    }

    .burger-menu{
        display: block;
    }

}


@media only screen and (max-width: 600px){
    .banner-contents .banner-text p {
        font-size: 19px;
    }

    .banner-contents .banner-text h1 {
        font-size: 33px;
    }

    #banner button{
        width: 100%;
        margin-bottom:10px;
        padding: 13px !important;
    }

    #banner .arrow-wrapper{
        margin-top: 10px;
    }

}